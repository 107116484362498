<template>
  <div class="result-data-entry">
    <div class="flex align-center mt-10 mb-20">
      <a-upload
        :file-list="fileList"
        :disabled="isSupervisor"
        :custom-request="() => {}"
        accept=".csv, .xlsx, text/csv, application/csv,
          text/comma-separated-values, application/csv, application/excel"
        :before-upload="beforeUpload">
        <a-button
          type="link"
          :disabled="isSupervisor"
          class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center">
          <embed width="30px" :disabled="isSupervisor" src="/Upload-orange.svg" class="mr-10" :class="{ 'disabled': isSupervisor }">
          {{ $t('components.description.uploadData') }}
        </a-button>
      </a-upload>
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="download">
              {{ $t('components.dropdown.downloadTableData') }}
            </a-menu-item>
            <a-menu-item @click="openOriginalSubmissionsDownloadModal">
              {{ $t('components.dropdown.downloadOriginalData') }}
            </a-menu-item>
            <a-menu-item @click="onAdditionalIndicatorsRequest">
              {{ $t('components.dropdown.downloadAdditionalIndicator') }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button
          type="link"
          class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center">
          <embed width="30px" src="/icon-cloud-download.svg" class="mr-10">
          {{ $t('components.description.downloadData') }}
        </a-button>
      </a-dropdown>
      <a-button
        type="link"
        class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center"
        @click="onPlausibilityRequest">
        <embed width="30px" src="/download-upload-icon.svg" class="mr-10">
        {{ $t('components.description.requestPlausibilityReport') }}
      </a-button>
    </div>
    <a-card :bordered="true" class="ant-card-no-padding mb-20">
      <div class="mb-10 pl-20 pr-20 pt-20">
        <div class="txt-20 txt-black txt-bold txt-font-din-medium mb-10 flex align-center">
          {{ $t('components.titles.dataEntry') }}
          <!--<a-tooltip title=" " class="ml-8">
            <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>-->
        </div>
        <DeleteDataSetModal
          :data-set="dataSetToDelete"
          :visible="deleteDataSetModalVisible"
          :source-column="headerKeys.indexOf('source')"
          :hot="$refs.hot && $refs.hot.hotInstance"
          :header-configs="headerConfigs"
          @close-modal="closeDeleteDataSetModal"
          @set-data-changes="setDataFromDeleteDatasetModal"/>
        <DatasetDisplay
          :source-column="headerKeys.indexOf('source')"
          :hot="$refs.hot && $refs.hot.hotInstance"
          @delete-data-set="deleteDataSet"/>
        <div class="flex justify-space-between mb-20">
          <div class="flex align-center">
            <div ref="duplication-container"></div>
            <DuplicationCheck
              :key="headerKeys.length"
              :clear-filter="clearFilter"
              :hot="$refs.hot && $refs.hot.hotInstance"
              :columns="duplicateHeaderkeys"
              :column-names="duplicateHeaderNames"
              :filtered-count="filters.length"
              :count="childrenCount"
              @selectedColumns="selectedColumnsEvent"/>
            <TableRowColumnControls :hot="$refs.hot && $refs.hot.hotInstance" :filters="filters"/>
          </div>
        </div>
        <div v-if="cellErrorCount !== 0" class="expand-error">
          <div class="pl-10 flex">
            <embed width="20px" height="20px" src="/alert.svg" class="mr-10 mt-20">
            <div>
              <div class="pt-20 flex">
                <p class="mr-20">
                  {{ $tc('components.notifications.cellsFoundWithError', cellErrorCount, { errors: cellErrorCount }) }}
                </p>
                <a class="mr-10 red-color txt-bold" @click="showErrorDetail=!showErrorDetail;">{{ !showErrorDetail ? $t('components.description.showMore') : $t('components.description.showLess') }}<embed src="/show-more-red.svg" :class="['ml-4', showErrorDetail ? '': 'rotate180']"></a>
              </div>
              <div v-show="showErrorDetail" class="pb-20">
                {{ $t('components.labels.errorsFoundInTheFollowingCols') }} <b class="txt-bold">{{ cellErrorColumns }}</b>
                <br>
                {{ $t('components.labels.errorsFoundInTheFollowingRows') }} <b class="txt-bold">{{ cellErrorRows }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlausibilityReportLoadingModal
        :title="$t('components.titles.preparingPlausibilityReport')"
        :show="showPlausibilityModal"
        :is-fetching="anthroPlausibilityIsFetching"
        :on-exit="closePlausibilityModal"
        :file-blob="anthroPlausibilityBlob"
        file-name="anthropometry_plausibility.docx"
        :request-time="plausibilityRequestTime"/>
      <DuplicationCount
        :selected-columns="selectedColumns"
        :hot="$refs.hot && $refs.hot.hotInstance"
        :hidden="hidden"
        :filtered="filters.length !== 0"
        :filtered-hidden-rows="filteredHiddenRows"
        :count="childrenCount"
        @clearDuplicateFilter="clearDuplicateFilter"/>
      <div :style="{'max-width': dataEntryWidth}">
        <hot-table
          ref="hot"
          class="table-body"
          stretch-h="none"
          license-key="non-commercial-and-evaluation"
          :settings="hotSettings"/>
      </div>
      <div class="txt-18 txt-bold txt-font-din-medium table-footer txt-black">
        {{ $t('components.labels.totalChildren', { children: childrenCount }) }}
      </div>
    </a-card>
    <div :class="['htCommentsContainer', hiddenComment]">
      <div :style="{display: 'block', position: 'fixed', left: leftComment + 'px', top: topComment + 'px'}" class="htComments">
        <div :class="['htCommentTextArea', colorComment]">
          <a :class="[colorComment + '-color', 'txt-bold']">{{ titleComment }}</a>
          <p>{{ contentComment }}</p>
        </div>
      </div>
    </div>
    <PlausibilityReportLoadingModal
      :title="$t('components.notifications.downloadingIndicators')"
      :show="showAdditionalIndicatorsModal"
      :is-fetching="additionalIndicatorIsFetching"
      :on-exit="closeAdditionalIndicatorModal"
      :file-blob="additionalIndicatorBlob"
      file-name="additional-indicators.docx"
      :request-time="plausibilityRequestTime"/>
    <OriginalSubmissionsDownloadModal
      :show="showOriginalSubmissionsDownloadModal"
      :on-exit="closeOriginalSubmissionsDownloadModal"/>
</div>
</template>

<script>
import Vue from 'vue';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import { mapGetters, mapActions, mapState } from 'vuex';
import anthroZscores from '@smartcomponents/anthro-js';
import { HotTable } from '@handsontable/vue';
import isFinite from 'lodash/isFinite';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';


import moment from 'moment';
import { handsontableFilterDropdown, handsontableRowContextMenu } from '../../shared';
import { convertStringToFloat, convertStringToNumeric, isEmptyOrNull } from '../../../../util/util';
import { getAnthropometrySettingsSheet } from '../../../../util/data-entry';

import { formatSmartDates, isAfterDate, validDateFormat, dateFormats } from '../../../../util/date-time';

import {
  filterEmptyChanges,
  filterNonChanges,
  getDataInHandsonTable,
  countFilledRows,
  shapeAnthroDateChanges,
  shapeAnthroUpdateRequestData,
  chunkDataUploadArray,
  shapeUploadRequestData,
  moveToNextRowAndOffset,
  moveToNextCol,
  applyAutoPopulationToCell
} from '../../../../util/handsontable';
import { configForPossibleBackendRequest } from '../../../../util/request';

const CUSTOM_SOURCES = [
  'setting_zscore',
  'setting_dates',
  'dateValidator',
  'update_id',
  'reversing_change'
];

const uploadMessageKey = 'uploadMessageKey';
export default {
  name: 'AnthropometryDataEntry',
  components: {
    HotTable,
    TableRowColumnControls: () => import('../../TableRowColumnControls/index'),
    DatasetDisplay: () => import('../../DatasetDisplay/index'),
    DeleteDataSetModal: () => import('../../DeleteDataSetModal/index'),
    DuplicationCheck: () => import('../../DuplicationCheck/index'),
    DuplicationCount: () => import('../../DuplicationCount/index'),
    PlausibilityReportLoadingModal: () => import('../../PlausibilityModal/plausibility-report-loading-modal.vue'),
    OriginalSubmissionsDownloadModal: () => import('../../Modals/original-submissions-download-modal.vue')
  },
  props: {
    disableClusters: {
      type: Boolean,
      required: false,
      default: undefined
    },
    onReloadAnthroData: {
      type: Function,
      required: true
    },
    onEntryChange: {
      type: Function,
      required: true
    }
  },
  data() {
    const translateDropdownMenu = handsontableFilterDropdown(this);
    const translateRowContext = handsontableRowContextMenu(this);
    return {
      requestTime: 0,
      deleteDataSetModalVisible: false,
      dataSetToDelete: '',
      backspaceDown: false,
      waitingForDataSetDeleteRequest: false,
      showErrorDetail: false,
      showPlausibilityModal: false,
      showAdditionalIndicatorsModal: false,
      plausibilityRequestTime: 0,
      plausibilityRequestTimeIntervalId: null,
      showOriginalSubmissionsDownloadModal: false,
      childrenCount: 0,
      hiddenComment: 'hidden',
      clearFilter: false,
      selectedColumns: false,
      colorComment: '',
      titleComment: '',
      contentComment: '',
      leftComment: 0,
      topComment: 0,
      mounted: false,
      isUploading: false,
      filters: [],
      fileList: [],
      fileName: undefined,
      hidden: [],
      filteredHiddenRows: [],
      data: [],
      anthroData: [],
      headerNames: [],
      duplicateHeaderNames: [],
      duplicateHeaderkeys: [],
      headerKeys: [],
      headerConfigs: [],
      variableRangeNames: [],
      zScoreColumnIndexes: [],
      columns: [],
      commentCells: {},
      cellErrorCount: 0,
      cellErrorColumns: '',
      cellErrorRows: '',
      filterApplied: false,
      // setting a fixed width for the data entry table, because setting
      // 100% width puts the scrollbar at the edge of the screen instead
      // of next to the table
      dataEntryWidth: '1626px',
      hotSettings: {
        height: 550,
        width: '100%',
        columnSorting: true,
        colWidths: 100,
        sortIndicator: false,
        rowHeaders: true,
        rowHeights: 24,
        fillHandle: false,
        autoRowSize: false,
        autoColumnSize: false,
        outsideClickDeselects: false,
        filters: true,
        hiddenRows: true,
        dropdownMenu: translateDropdownMenu,
        contextMenu: translateRowContext,
        minSpareRows: 10,
        minRows: 20,
        comments: false,
        wordWrap: false,
        beforeRemoveRow: (index, amount, physicalRows) => {
          this.$message.loading({ content: this.$t('components.notifications.deletingData'), key: uploadMessageKey });
        },
        afterRemoveRow: (index, amount, physicalRows) => {
          for (const physicalRow of physicalRows) {
            delete this.commentCells[physicalRow];
          }
          this.updateCellErrorBarData();
        },
        afterColumnSort: (currentSortConfig, destinationSortConfigs) => {
          this.reRenderingCells(true);
          this.updateCellErrorBarData();
        },
        afterOnCellMouseOver: (event, coords, TD) => {
          if (TD.classList.contains('htCommentCell')) {
            const physicalRow = this.$refs.hot.hotInstance.toPhysicalRow(coords.row);
            this.colorComment = this.commentCells[physicalRow][coords.col].color;
            this.titleComment = this.commentCells[physicalRow][coords.col].errorTitle;
            this.contentComment = this.commentCells[physicalRow][coords.col].errorMessage;
            this.hiddenComment = '';
            const rect = TD.getBoundingClientRect();
            this.leftComment = rect.x + rect.width;
            this.topComment = rect.y;
          }
        },
        afterOnCellMouseOut: (event, coords, TD) => {
          this.hiddenComment = 'hidden';
        },
        enterMoves: () => {
          const instance = this.$refs.hot.hotInstance;
          const selectedRow = instance.getSelected();
          const colCount = instance.countCols();
          const currentRow = selectedRow[0][0];
          const currentCol = selectedRow[0][1];

          const nextCellMeta = instance.getCellMeta(currentRow, currentCol + 1);
          if (nextCellMeta.readOnly) {
            let offsetValue = 1;
            let i = currentCol + 1;
            while (i < colCount) {
              const cellMeta = instance.getCellMeta(currentRow, i);
              if (cellMeta.readOnly) {
                offsetValue += 1;
              } else {
                break;
              }
              i += 1;
            }
            // Check for last several rows being read only
            if (i === colCount) {
              applyAutoPopulationToCell(instance, this.autoPopulation, this.headerKeys, currentRow);
              return moveToNextRowAndOffset(instance, currentRow, 2); // account for id, hh_id
            }

            return {
              row: 0,
              col: offsetValue
            };
          }

          // enter hit on last row
          if (currentCol === instance.countCols() - 1) {
            applyAutoPopulationToCell(instance, this.autoPopulation, this.headerKeys, currentRow);
            return moveToNextRowAndOffset(instance, currentRow, 2); // account for id, hh_id
          }

          const ageFormatSkipMap = {
            months: 'birthdate',
            birthday: 'months'
          };

          // Age format check
          if (Object.prototype.hasOwnProperty.call(ageFormatSkipMap, this.ageFormat)) {
            const skipColName = ageFormatSkipMap[this.ageFormat];
            const itemIndex = this.headerKeys.indexOf(skipColName);

            if (itemIndex === currentCol + 1) {
              return {
                row: 0,
                col: 2
              };
            }
          }
          return moveToNextCol();
        },
        cells: (row, column) => this.validateCellClassName(row, column),
        afterHideRows: (currentHideConfig, destinationHideConfig) => {
          if (this.filters.length === 0) {
            this.hidden = [...destinationHideConfig.filter(item => item !== null)];
          } else {
            this.filteredHiddenRows = [...destinationHideConfig.filter(item => item !== null)];
          }
        },
        beforePaste: (data, coords) => {
          data.forEach((rowData, rowIndex) => {
            rowData.forEach((columnData, columnIndex) => {
              // eslint-disable-next-line no-param-reassign
              data[rowIndex][columnIndex] = columnData.replace(/\r?\n|\r/g, '');
            });
          });
        },
        afterPaste: () => {
          this.$refs.hot.hotInstance.deselectCell();
        },
        afterFilter: (conditions) => {
          this.filters = [...conditions];
          this.reRenderingCells(false);
          this.filterApplied = conditions.length !== 0;
          this.updateCellErrorBarData();
        },
        beforeKeyDown: (event) => {
          const key = event.keyCode || event.charCode;
          // 8 is backspace, 46 is delete
          if (key === 8 || key === 46) {
            this.backspaceDown = true;
          }
        },
        afterChange: (changes, source) => {
          const updates = [];
          if (CUSTOM_SOURCES.includes(source)) {
            return;
          }
          if (changes && filterNonChanges(changes).length === 0) {
            return;
          }

          if (source === 'loadData' || source === 'updateData' || source === 'updateTableAfterFileSave') {
            this.reComputeZScores();
          }
          const editableHeaderIndexes = this.headerConfigs.filter((cnfg) => !cnfg.config.readOnly).map((cnfg) => this.headerKeys.indexOf(cnfg.key));
          let changesWithFilteredDate = changes;
          if ((source === 'CopyPaste.paste' || source === 'CopyPaste.cut' || source === 'edit' || source === 'populateFromArray' || source === 'UndoRedo.undo' || source === 'UndoRedo.redo') && changes) {
            const monthIndex = this.headerKeys.indexOf('months');
            const survDateIndex = this.headerKeys.indexOf('survdate');
            const birthDateIndex = this.headerKeys.indexOf('birthdate');

            const rowsToUpdate = uniq(filterEmptyChanges(changes).map((change) => change[0]));
            const rowsToDelete = rowsToUpdate.filter((rowNumber) => {
              const rowChanges = changes.filter((chng) => chng[0] === rowNumber);
              const birthdateChange = rowChanges.find((chang) => chang[1] === birthDateIndex);
              const birthdatePrevValue = birthdateChange && birthdateChange[2];
              return rowChanges && editableHeaderIndexes.filter((indx) => isEmptyOrNull(birthdatePrevValue) || indx !== monthIndex).every((indx) => {
                const chng = rowChanges.find((chang) => chang[1] === indx);
                return chng && chng[3] === null;
              });
            });
            const normalizedSubmissionIdIndex = this.headerKeys.indexOf('normalized_submission_id');
            const updatesWithDateGeneration = this.getFilteredUpdatesForDateGeneration(changes);
            const rowIdsOfItemsBeingUpdated = updatesWithDateGeneration.map((change) => change[0]);

            changesWithFilteredDate = changes.filter(change =>
              rowsToDelete.includes(change[0]) ||
              // We want to keep any changes to the row ids, the only time this happens is during deletion
              [normalizedSubmissionIdIndex].includes(change[1]) ||
              // Any non date related changes should not be lost
              (![monthIndex, survDateIndex, birthDateIndex].includes(change[1])) ||
              // We want to filter out any month column out already being updated by a survdate/birthdate change
              (rowIdsOfItemsBeingUpdated.includes(change[0]) && ![monthIndex].includes(change[1])) ||
              // We also want to include any month column changes without any survdate/birthdate changes
              (!rowIdsOfItemsBeingUpdated.includes(change[0]) && [monthIndex].includes(change[1])));
            const additionalUpdates = this.calculateDate(updatesWithDateGeneration);
            const dateGenerationUpdates = additionalUpdates.filter((itm) => !((source === 'UndoRedo.undo' || source === 'UndoRedo.redo' || source === 'CopyPaste.paste') && itm[1] === monthIndex && isEmptyOrNull(itm[2])) && !rowsToDelete.includes(itm[0]));
            changesWithFilteredDate.push(
              ...dateGenerationUpdates.map(([row, col, newVal]) => [row, col, null, newVal]),
            );
            rowsToUpdate.forEach((changingRow) => {
              const zScoreResults = this.calculateZscores(changingRow);
              updates.push(...zScoreResults);
            });
            this.$refs.hot.hotInstance.batch(() => {
              this.$refs.hot.hotInstance.setDataAtCell(dateGenerationUpdates, 'setting_dates');
              this.$refs.hot.hotInstance.setDataAtCell(updates, 'setting_zscore');
            });
          }

          // Save Row edits, new row additions, but not file uploads
          if (changes && !this.isUploading && source !== 'populateFromArray' && source !== 'updateTableAfterFileSave') {
            this.saveRow(changesWithFilteredDate.map((item) => [...item, source]));
          } else {
            this.countRows();
          }
        },
        afterRender: () => {
          // This logic is used to improve the perceived
          // performance allowing the tab to load before fetching data
          if (!this.mounted) {
            setTimeout(() => this.setDataFromStore(this.normalizedAnthroData), 1000);
          }
          this.mounted = true;
        },
        // eslint-disable-next-line consistent-return
        beforeChange: (changes, source) => {
          if (source === 'UndoRedo.undo' || source === 'UndoRedo.redo') {
            if (changes.every((item) => {
              const [_, col, oldVal, newVal] = item;
              return col === 0 && oldVal != null && newVal != null;
            })) {
              this.$refs.hot.hotInstance.undoRedo.undo();
              return false;
            }
          }
          if (source === 'setting_dates') {
            // eslint-disable-next-line consistent-return
            return;
          }
          if (source === 'CopyPaste.paste' || source === 'edit') {
            for (let index = 0; index < changes.length; index += 1) {
              const change = changes[index];
              if (change[3] != null) {
                switch (change[1]) {
                  case this.headerKeys.indexOf('sex'):
                    if (change[3] === 'M' || change[3] === 'F') {
                      change[3] = change[3].toLowerCase();
                    }
                    break;
                  case this.headerKeys.indexOf('survdate'):
                    change[3] = formatSmartDates(change[3]);
                    break;
                  case this.headerKeys.indexOf('birthdate'):
                    change[3] = formatSmartDates(change[3]);
                    break;
                  case this.headerKeys.indexOf('edema'):
                  case this.headerKeys.indexOf('clothes'):
                    if (change[3] === 'Y' || change[3] === 'N') {
                      change[3] = change[3].toLowerCase();
                    }
                    break;
                  case this.headerKeys.indexOf('measure'):
                    if (change[3] === 'L' || change[3] === 'H') {
                      change[3] = change[3]?.toLowerCase?.();
                    }
                    break;
                  case this.headerKeys.indexOf('months'):
                  case this.headerKeys.indexOf('weight'):
                  case this.headerKeys.indexOf('height'):
                  case this.headerKeys.indexOf('muac'):
                    change[3] = change[3]?.replace?.(',', '.');
                    break;
                  case this.headerKeys.indexOf('wtfactor'):
                    if (change[3] !== '' && isFinite(+(change[3]))) {
                      change[3] = +(change[3]);
                    } else {
                      change[3] = null;
                    }
                    break;
                  default:
                }
              }
            }
          }
        },
        afterUndo: (action) => {
          // workaround for undoing the months changes after birthdate change
          const birthdateIndex = this.headerConfigs.findIndex((config) => config.key === 'birthdate');
          const monthsIndex = this.headerConfigs.findIndex((config) => config.key === 'months');
          if (action && action.actionType === 'change' && action.changes.every((chng) => chng[1] === monthsIndex)) {
            const nextUndoAction = this.$refs.hot.hotInstance.undoRedo.doneActions[this.$refs.hot.hotInstance.undoRedo.doneActions.length - 1];
            if (nextUndoAction && nextUndoAction.actionType === 'change' && action.changes.every((chng) => nextUndoAction.changes.some((nChng) => nChng[0] === chng[0] && nChng[1] === birthdateIndex))) {
              this.$refs.hot.hotInstance.undoRedo.undo();
            }
          } else if (action && action.actionType === 'remove_row') {
            // if undoing a remove_row, afterChanges is not called so we need to save the changes here
            const changes = action.data.flatMap((row, rowIndexOffset) => row.map((item, index) => [action.index + rowIndexOffset, index, null, item, action.actionType]));
            this.saveRow(changes);
          }
        },
        beforeUndo: (action) => {
          if (action && action.data && action.actionType === 'remove_row') {
            const pkHeaderIndex = this.headerConfigs.findIndex((config) => config.key === 'normalized_submission_id');
            if (action.data.some((row) => row[pkHeaderIndex] === null)) {
              return true;
            }
            const removedIndexes = action.data.map((_, index) => action.index + index);
            if (this.$refs.hot.hotInstance?.undoRedo?.doneActions && this.$refs.hot.hotInstance.undoRedo.doneActions.length > 0) {
              const beforeRemoveAction = this.$refs.hot.hotInstance.undoRedo.doneActions[this.$refs.hot.hotInstance.undoRedo.doneActions.length - 1];
              const changedRowsUniqIndexes = beforeRemoveAction?.changes ? uniq(beforeRemoveAction.changes.map((change) => change[0])) : [];
              if (beforeRemoveAction && (beforeRemoveAction.actionType === 'remove_row' || (beforeRemoveAction.actionType === 'change' && removedIndexes.length > 0 && removedIndexes.every((index) => changedRowsUniqIndexes.includes(index))))) {
                this.$refs.hot.hotInstance.alter('insert_row', action.index, action.data.length, 'undo_row_remove');
                return false;
              }
            }
          }
          return true;
        },
        beforeUndoStackChange: (_, source) => {
          if (source === 'undo_row_remove') {
            this.$refs.hot.hotInstance.undoRedo.undo();
            return false;
          }
          if (source === 'setting_zscore' || source === 'undo_row_remove' || source === 'update_id') {
            return false;
          }
          return true;
        }
      }
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      surveyName: state => state.survey.name,
      projectId: state => state.survey.project.id,
      plausibilityError: state => state.plausibility.error,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    ...mapGetters([
      'normalizedAnthroData',
      'clothingWeight',
      'ageFormat',
      'autoPopulation',
      'variableRanges',
      'zScoreValues',
      'restrictedAnalysis',
      'anthroPlausibilityBlob',
      'anthroPlausibilityIsFetching',
      'additionalIndicatorBlob',
      'additionalIndicatorIsFetching',
      'anthroSettings'
    ])
  },
  watch: {
    restrictedAnalysis() {
      this.onReloadAnthroData();
    },
    clothingWeight() {
      this.reComputeZScores();
    },
    variableRanges() {
      this.reComputeVariableRanges();
    },
    normalizedAnthroData() {
      this.setDataFromStore(this.normalizedAnthroData);
    },
    '$i18n.locale': function() {
      const translatedHeaders = this.translateHeaders();
      const headerNames = this.headerKeys.map((key) => (translatedHeaders.find((k) => (key === k.key)).title));
      const translateDropdownMenu = handsontableFilterDropdown(this);
      const translateRowContext = handsontableRowContextMenu(this);
      this.$refs.hot.hotInstance.updateSettings({
        colHeaders: headerNames,
        dropdownMenu: translateDropdownMenu,
        contextMenu: translateRowContext
      });
      this.duplicateHeaderNames = this.duplicateHeaderkeys.map((key) => translatedHeaders.find((x) => x.key === key).title);
      this.updateCellErrorBarData();
    }
  },
  mounted() {
    this.$message.loading({ content: this.$t('components.notifications.loadingDataAndRunningCalculations'), duration: 1 });
    if (this.isSupervisor && this.$refs?.hot?.hotInstance) {
      this.$refs.hot.hotInstance.updateSettings({
        readOnly: true,
        contextMenu: false,
        manualColumnResize: false,
        manualRowResize: false,
        comments: false
      });
    }
  },
  methods: {
    ...mapActions(['getSurveyAnthroPlausibility', 'cancelSurveyAnthroPlausibilityRequest', 'getAdditionalIndicatorReport', 'cancelAdditionalIndicatorRequest']),
    selectedColumnsEvent($event) {
      this.selectedColumns = $event;
    },
    clearDuplicateFilter() {
      this.clearFilter = !this.clearFilter;
    },
    async onPlausibilityRequest() {
      this.showPlausibilityModal = true;
      this.plausibilityRequestTime = 0;
      this.plausibilityRequestTimeIntervalId = setInterval(() => {
        this.plausibilityRequestTime += 1;
      }, 1000);
      const successful = await this.getSurveyAnthroPlausibility({
        projectId: this.projectId,
        surveyId: this.surveyId,
        lang: this.$i18n.locale
      });
      if (!successful) {
        let content = this.$t('components.notifications.errorRunningPlausibility');
        if (this.plausibilityError && this.plausibilityError.code === 409.11) {
          content = this.$t('components.notifications.notEnoughDataToGenerateReport');
        }
        this.$message.error({
          content,
          duration: 5
        });
        this.showPlausibilityModal = false;
      }
      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }
    },
    async onAdditionalIndicatorsRequest() {
      this.showAdditionalIndicatorsModal = true;
      this.plausibilityRequestTime = 0;
      this.plausibilityRequestTimeIntervalId = setInterval(() => {
        this.plausibilityRequestTime += 1;
      }, 1000);
      const successful = await this.getAdditionalIndicatorReport({
        surveyId: this.surveyId,
        lang: this.$i18n.locale
      });
      if (!successful) {
        this.$message.error({
          content: this.$t('components.notifications.errorRunningAdditionalIndicators'),
          duration: 5
        });
        this.showAdditionalIndicatorsModal = false;
      }
      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }
    },
    closePlausibilityModal() {
      this.showPlausibilityModal = false;
      this.plausibilityRequestTime = 0;
      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }
      this.cancelSurveyAnthroPlausibilityRequest();
    },
    closeAdditionalIndicatorModal() {
      this.showAdditionalIndicatorsModal = false;
      this.plausibilityRequestTime = 0;
      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }
      this.cancelAdditionalIndicatorRequest();
    },
    setCommentAtCell(row, column, commentAttr) {
      if (!this.commentCells[row]) {
        this.commentCells[row] = { [column]: commentAttr };
      } else {
        this.commentCells[row][column] = commentAttr;
      }
    },
    reRenderingCells(visual) {
      const filterData = this.$refs.hot.hotInstance.getData();
      filterData.forEach((item, row) => {
        item.forEach((i, column) => {
          this.validateCellClassName(row, column, visual);
        });
      });
    },
    reComputeVariableRanges() {
      const instance = this.$refs.hot.hotInstance;

      for (const varRange of this.variableRanges) {
        const colIndex = this.headerKeys.findIndex(key => key === varRange.name);
        if (colIndex > -1) {
          const colData = instance.getDataAtCol(colIndex);

          for (let i = 0; i < colData.length; i += 1) {
            const value = colData[i];
            const failedValidation = this.hasFailedVariableRangeValidation(varRange, value);
            instance.setCellMeta(i, colIndex, 'className', failedValidation ? 'htCommentCell orange' : '');
          }
        }
      }
      instance.render();
    },
    hasFailedVariableRangeValidation(varRange, value) {
      return value !== null && value !== '' && varRange && (varRange.start > value || value > varRange.end);
    },
    validateCellClassName(row, column, visual = false) {
      const cellProperties = {};
      const cellClassNames = [];
      const instance = this.$refs.hot.hotInstance;
      const physicalRow = !this.filterApplied || visual ? instance.toPhysicalRow(row) : row;
      if (!physicalRow && physicalRow !== 0) {
        return cellProperties;
      }
      const visualRow = !this.filterApplied || visual ? row : instance.toVisualRow(row);
      if (!visualRow && visualRow !== 0) {
        return cellProperties;
      }
      const cellValue = instance.getDataAtCell(visualRow, column);
      let commentAttr = {};
      if (column === this.headerKeys.indexOf('survdate') && !this.hasValidDate(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.dateFormatNotAcceptable'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('birthdate')) {
        if (!this.hasValidDate(cellValue)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.dateFormatNotAcceptable'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (this.hasValidBirthDate(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.survdateLaterThanBirth'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if ((column === this.headerKeys.indexOf('months') || column === this.headerKeys.indexOf('weight') || column === this.headerKeys.indexOf('height') || column === this.headerKeys.indexOf('muac')) && !this.hasValidPositiveZero(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.pleaseEnterNonNegativeValue'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('sex') && !this.hasValidSex(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.pleaseEnterMaleOrFemale'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('cluster')) {
        if (this.disableClusters) {
          cellClassNames.push('htDimmed');
        } else if ((!cellValue && cellValue !== 0) && !instance.isEmptyRow(visualRow)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.clusterColumnMissingData'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if (column === this.headerKeys.indexOf('strata') && !instance.isEmptyCol(column) && (!cellValue && cellValue !== 0) && !instance.isEmptyRow(visualRow)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.strataColumnEntirelyEmptyOrFilled'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('wtfactor')) {
        if (!instance.isEmptyCol(column) && (!cellValue && cellValue !== 0) && !instance.isEmptyRow(visualRow)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.wtfactorColumnEntirelyEmptyOrFilled'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidWTFactor(cellValue)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.pleaseEnterNonNegativeValue'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if (column === this.headerKeys.indexOf('edema') && !this.hasValidYesNo(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.pleaseEnterYesOrNo'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('clothes')) {
        if (!this.hasValidYesNo(cellValue)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.pleaseEnterYesOrNo'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidClothes(cellValue)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.reviseZeroGrams'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if (column === this.headerKeys.indexOf('measure') && !this.hasValidMeasure(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.pleaseEnterLengthOrHeight'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (this.variableRangeNames.includes(column)) {
        const varRange = this.variableRanges.find(range => range.name === this.headerKeys[column]);
        const failedValidation = this.hasFailedVariableRangeValidation(varRange, cellValue);
        if (failedValidation) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.valueOutOfRange'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if (this.zScoreColumnIndexes.includes(column)) {
        const varRange = this.zScoreValues.who[this.headerKeys[column]];
        const failedValidation = this.hasFailedVariableRangeValidation(varRange, cellValue);
        if (failedValidation) {
          cellClassNames.push('htCommentCell blue');
          commentAttr = {
            visualRow,
            color: 'blue',
            errorMessage: this.$t('components.notifications.valueIsWHOFlag'),
            errorTitle: this.$t('components.notifications.whoFlag')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      }
      if (column === this.headerKeys.indexOf('months')) {
        const birthdate = instance.getDataAtCell(visualRow, this.headerKeys.indexOf('birthdate'));
        const survdate = instance.getDataAtCell(visualRow, this.headerKeys.indexOf('survdate'));
        if ((birthdate && survdate) || this.isSupervisor) {
          cellProperties.readOnly = true;
        } else {
          cellProperties.readOnly = false;
        }
      }
      if ((!this.filterApplied || visual) && this.commentCells[physicalRow] && this.commentCells[physicalRow][column] && Object.keys(commentAttr).length === 0) {
        delete this.commentCells[physicalRow][column];
      }
      this.$refs.hot.hotInstance.setCellMeta(visualRow, column, 'className', cellClassNames.join(' '));
      return cellProperties;
    },
    hasValidDate(value) {
      if (value) {
        return validDateFormat(value, dateFormats);
      }
      return true;
    },
    hasValidSex(value) {
      return (value === 'm' || value === 'f' || !value);
    },
    hasValidBirthDate(row, value) {
      const survdateColumn = this.headerKeys.indexOf('survdate');
      const survdate = this.$refs.hot.hotInstance.getDataAtCell(row, survdateColumn);
      return isAfterDate(value, survdate);
    },
    hasValidPositiveZero(value) {
      return (!value && parseInt(value, 10) !== 0) || (!Number.isNaN(Number(value)) && Number(value) >= 0);
    },
    hasValidWTFactor(value) {
      return (!value && parseInt(value, 10) !== 0) || (!Number.isNaN(Number(value)) && Number(value) > 0);
    },
    hasValidYesNo(value) {
      return (value === 'y' || value === 'n' || !value);
    },
    hasValidClothes(value) {
      return !(this.clothingWeight === 0 && value === 'y');
    },
    hasValidMeasure(value) {
      return (value === 'h' || value === 'l' || !value);
    },
    reComputeZScores() {
      const updates = [];
      this.data
        .filter((cols) => cols[0] != null)
        .forEach((_, index) => {
          const zScoreResults = this.calculateZscores(index);
          updates.push(...zScoreResults);
        });

      this.$refs.hot.hotInstance.batchRender(() => {
        this.$refs.hot.hotInstance.setDataAtCell(updates, 'setting_zscore');
      });
    },
    setDataFromStore() {
      const normData = cloneDeep(this.normalizedAnthroData);

      if (normData && normData.headers && normData.items) {
        const { items, headers } = normData;

        const hiddenColumnIndexes = [];
        headers.forEach((header, index) => {
          if (header.isHidden) {
            hiddenColumnIndexes.push(index);
          }
        });
        const translatedHeaders = this.translateHeaders();
        const headerNames = headers.map((item) => translatedHeaders.find((x) => x.key === item.key).title);
        const headerKeys = headers.map((item) => item.key);
        const headerWidths = headers.map(item => item.width);
        const variableRangeNames = [];
        const zScoreColumnIndexes = [];
        headerKeys.forEach((keyName, index) => {
          const item = this.variableRanges
            .find(range => range.name === keyName);

          if (item && item !== null) {
            variableRangeNames.push(index);
          }
          if (keyName in this.zScoreValues.who) {
            zScoreColumnIndexes.push(index);
          }
        });
        const zScoreHeaderTypes = {
          haz: 'numeric',
          waz: 'numeric',
          whz: 'numeric'
        };
        const headerConfigs = headers.map((item) => {
          const result = item?.config ? item.config : {};
          result.validator = null;
          if (item.config && 'editor' in item.config && item.config.editor === false) {
            result.editor = false;
          }
          if (this.isSupervisor) {
            result.readOnly = true;
            result.editor = false;
          }
          result.type = zScoreHeaderTypes[item.key] ? zScoreHeaderTypes[item.key] : result.type;
          return result;
        });
        const dataValues = items.map((item) => {
          const result = [];
          for (const headerKey of headers) {
            if (headerKey?.config?.dateFormat) {
              const dates = formatSmartDates(item[headerKey.key], headerKey.config.dateFormat);
              result.push(dates);
            } else if (headerKey?.config?.type === 'numeric') {
              result.push(convertStringToNumeric(item[headerKey.key]));
            } else {
              result.push(item[headerKey.key]);
            }
          }
          return result;
        });

        this.headerConfigs = headers;
        const clusterColIndex = headerKeys.indexOf('cluster');
        if (this.disableClusters) {
          headerConfigs[clusterColIndex].readOnly = true;
        }
        this.variableRangeNames = variableRangeNames;
        this.zScoreColumnIndexes = zScoreColumnIndexes;
        this.headerNames = headerNames;
        const duplicateHeader = headers.filter(item => item.key !== 'normalized_submission_id');
        this.duplicateHeaderNames = duplicateHeader.map((item) => translatedHeaders.find((x) => x.key === item.key).title);
        this.duplicateHeaderkeys = duplicateHeader.map(item => item.key);
        this.headerKeys = headerKeys;
        this.anthroData = items;
        this.data = dataValues;
        this.$refs.hot.hotInstance.updateSettings({
          colHeaders: headerNames,
          columns: headerConfigs,
          colWidths: headerWidths,
          data: dataValues,
          hiddenColumns: {
            columns: hiddenColumnIndexes,
            indicators: false,
            copyPasteEnabled: false
          }
        });
        this.reRenderingCells(false);
        this.updateCellErrorBarData();
      }
    },
    download() {
      const tableData = this.$refs.hot.hotInstance.getSourceData();
      if (tableData && this.headerKeys && this.headerNames) {
        const wb = XLSX.utils.book_new();
        const dataObjects = tableData.map((item) => {
          let result = {};
          this.headerConfigs.forEach((header, hIndex) => {
            if (header?.config?.dateFormat) {
              result = { ...result, [this.headerNames[hIndex]]: item[hIndex] ? moment(item[hIndex], header.config.dateFormat).toDate() : '' };
            } else if (header?.config?.type === 'numeric' || header?.key === 'normalized_submission_id') {
              result = { ...result, [this.headerNames[hIndex]]: convertStringToNumeric(item[hIndex]) };
            } else {
              result = { ...result, [this.headerNames[hIndex]]: item[hIndex] };
            }
          });
          return result;
        });
        const ws = XLSX.utils.json_to_sheet(dataObjects, { header: this.headerNames, dense: true });
        this.headerConfigs.forEach((hConfig, index) => {
          if (hConfig?.config?.dateFormat) {
            for (let i = 0; i < dataObjects.length; i += 1) {
              const cellRef = XLSX.utils.encode_cell({ r: i + 1, c: index });
              ws[cellRef].z = 'yyyy-mm-dd';
            }
          }
        });
        XLSX.utils.book_append_sheet(wb, ws, 'data');

        const settingWs = getAnthropometrySettingsSheet(this.anthroSettings);
        XLSX.utils.book_append_sheet(wb, settingWs, 'settings');

        XLSX.writeFile(wb, `survey ${this.surveyName} (${this.surveyId}) - anthropometry-data.xlsx`, { compression: true });
      }
    },
    async openOriginalSubmissionsDownloadModal() {
      this.showOriginalSubmissionsDownloadModal = true;
    },
    closeOriginalSubmissionsDownloadModal() {
      this.showOriginalSubmissionsDownloadModal = false;
    },
    updateCellErrorBarData() {
      const columnSet = new Set();
      const rowSet = new Set();
      this.cellErrorCount = 0;
      Object.keys(this.commentCells).forEach(row => {
        Object.keys(this.commentCells[row]).forEach(column => {
          if (this.commentCells[row][column].color === 'red') {
            this.cellErrorCount = this.cellErrorCount + 1;
            columnSet.add((this.translateHeaders().find((h) => (this.headerKeys[column] === h.key))?.title || '').toUpperCase());
            rowSet.add(parseInt(this.commentCells[row][column].visualRow, 10) + 1);
          }
        });
      });
      this.cellErrorColumns = Array.from(columnSet).join(', ');
      const reduceRows = {};
      Array.from(rowSet).forEach(item => {
        if (item - 1 in reduceRows) {
          reduceRows[item] = reduceRows[item - 1];
          delete reduceRows[item - 1];
        } else {
          reduceRows[item] = item;
        }
      });
      this.reduceSequence(reduceRows);
      this.cellErrorRows = '';
      Object.keys(reduceRows).forEach(item => {
        let cellErrorRows = '';
        if (parseInt(reduceRows[item], 10) !== parseInt(item, 10)) {
          cellErrorRows = `${reduceRows[item]}-${item}`;
        } else {
          cellErrorRows = `${item}`;
        }
        this.cellErrorRows = this.cellErrorRows !== '' ? `${this.cellErrorRows}, ${cellErrorRows}` : cellErrorRows;
      });
    },
    reduceSequence(dict) {
      for (const item of Object.keys(dict)) {
        if (dict[item] - 1 in dict) {
          /* eslint-disable no-param-reassign */
          const minItem = dict[dict[item] - 1];
          delete dict[dict[item] - 1];
          dict[item] = minItem;
          return this.reduceSequence(dict);
          /* eslint-disable no-param-reassign */
        }
      }
      return dict;
    },
    beforeUpload(file) {
      this.$message.loading({ content: this.$t('components.notifications.uploadingData'), key: uploadMessageKey });
      this.$root.$emit('clearAllDataEntryFilters');
      this.isUploading = true;
      this.fileName = file.name;
      const fileNameLowercased = file.name.toLowerCase();

      if (fileNameLowercased.endsWith('.xlsx') || fileNameLowercased.endsWith('.xls')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          // lowercase headers
          const range = XLSX.utils.decode_range(ws['!ref']);
          for (let C = range.s.c; C <= range.e.c; C += 1) {
            const address = `${XLSX.utils.encode_col(C)}1`; // <-- first row, column number C
            if (ws[address]) {
              ws[address].v = ws[address].v.toLowerCase();
            }
          }

          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankRows: false });
          await this.uploadDataFromFile(data, this.fileName);
          this.fileName = undefined;
          this.isUploading = false;
        };
        reader.readAsBinaryString(file);
      } else {
        Papa.parse(file, {
          skipEmptyLines: 'greedy',
          worker: true,
          complete: async (result) => {
            await this.uploadDataFromFile(result.data, this.fileName);
            this.fileName = undefined;
            this.isUploading = false;
          },
          error: (err => this.$message.danger({ content: this.$t('components.notifications.errorImportingCSV'), key: uploadMessageKey, duration: 2 }))
        });
      }
    },
    async uploadDataFromFile(data, fileName) {
      const trimmedPathName = fileName.substring(0, fileName.lastIndexOf('.'));
      const shaped = data
        .filter(row => row.length > 0);

      const headerRow = shaped[0].map(headerName => headerName.toLowerCase());
      const coreHeaderConfigs = this.headerConfigs
        .filter((_, index) =>
          index !== this.headerKeys.indexOf('normalized_submission_id') &&
          index !== this.headerKeys.indexOf('waz') &&
          index !== this.headerKeys.indexOf('haz') &&
          index !== this.headerKeys.indexOf('whz'));

      try {
        for (let index = 1; index < shaped.length; index += 1) {
          const change = shaped[index];
          for (let headerIndex = 0; headerIndex < headerRow.length; headerIndex += 1) {
            if (change[headerIndex] || change[headerIndex] === 0) {
              switch (headerRow[headerIndex]) {
                case 'sex':
                  if (change[headerIndex] === 'M' || change[headerIndex] === 'F') {
                    change[headerIndex] = change[headerIndex].toLowerCase();
                  }
                  break;
                case 'survdate':
                  change[headerIndex] = formatSmartDates(change[headerIndex]);
                  break;
                case 'birthdate':
                  change[headerIndex] = formatSmartDates(change[headerIndex]);
                  break;
                case 'edema':
                case 'clothes':
                  if (change[headerIndex] === 'Y' || change[headerIndex] === 'N') {
                    change[headerIndex] = change[headerIndex].toLowerCase();
                  }
                  break;
                case 'measure':
                  if (change[headerIndex] === 'L' || change[headerIndex] === 'H') {
                    change[headerIndex] = change[headerIndex].toLowerCase();
                  }
                  break;
                case 'months':
                case 'weight':
                case 'height':
                case 'muac': {
                  const newValue = change[headerIndex]?.replace?.(',', '.');
                  change[headerIndex] = newValue || change[headerIndex];
                  break;
                }
                default:
              }
            } else {
              change[headerIndex] = null;
            }
          }
        }
        const chunkedDataUpload = chunkDataUploadArray(shaped.slice(1));
        const projectId = this.$store.state.survey.project.id;
        const { surveyId } = this.$store.state.survey;
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;

        const axiosConfig = {
          method: 'POST',
          url: `/projects/${projectId}/child-anthro/${surveyId}`,
          data: []
        };
        const updatePromises = chunkedDataUpload.map(dataItemsToUpload => {
          const reqData = shapeUploadRequestData(coreHeaderConfigs, headerRow, dataItemsToUpload, trimmedPathName);
          axiosConfig.data = reqData;

          return Vue.prototype.$http.request(
            configForPossibleBackendRequest(axiosConfig, token)
          );
        });
        const responses = await Promise.all(updatePromises);
        const itemsToUpdate = responses
          .reduce((prevUpdates, nextResponse) => prevUpdates.concat(nextResponse.data), []);

        this.$refs.hot.hotInstance.batch(() => {
          this.updateTableAfterFileSave(itemsToUpdate);
        });
        this.$message.success({
          content: this.$t('components.notifications.importedDataFileReset'),
          key: uploadMessageKey,
          duration: 5
        });
        this.isUploading = false;
        this.countRows();
        this.reRenderingCells(true);
        this.updateCellErrorBarData();
      } catch (error) {
        this.$message.error({ content: this.$t('components.notifications.errorUploadingData'), duration: 5 });
      }
    },
    getFilteredUpdatesForDateGeneration(changes) {
      const survDateIndex = this.headerKeys.indexOf('survdate');
      const birthDateIndex = this.headerKeys.indexOf('birthdate');
      const nonEmptyChanges = filterEmptyChanges(changes);

      // filter so atleast survdate/birthdate has been changed
      const filteredUpdatesForGeneration = nonEmptyChanges
        .filter(change => [survDateIndex, birthDateIndex].includes(change[1]));
      return filteredUpdatesForGeneration;
    },
    calculateDate(filteredUpdatesForGeneration) {
      const monthIndex = this.headerKeys.indexOf('months');

      const updatesToMonths = shapeAnthroDateChanges(
        filteredUpdatesForGeneration,
        this.$refs.hot.hotInstance,
        this.headerConfigs,
        monthIndex,
      );
      return updatesToMonths;
    },
    calculateZscores(rowIndex) {
      const zScoresToUpdate = [];
      const instance = this.$refs.hot.hotInstance;
      const sex = getDataInHandsonTable(instance, rowIndex, this.headerKeys.indexOf('sex'));
      const age = getDataInHandsonTable(instance, rowIndex, this.headerKeys.indexOf('months'));
      const weight = getDataInHandsonTable(instance, rowIndex, this.headerKeys.indexOf('weight'));
      const height = getDataInHandsonTable(instance, rowIndex, this.headerKeys.indexOf('height'));
      const edema = getDataInHandsonTable(instance, rowIndex, this.headerKeys.indexOf('edema'));
      const measure = getDataInHandsonTable(instance, rowIndex, this.headerKeys.indexOf('measure'));
      const clothes = getDataInHandsonTable(instance, rowIndex, this.headerKeys.indexOf('clothes'));

      const wazIndex = this.headerKeys.indexOf('waz');
      const hazIndex = this.headerKeys.indexOf('haz');
      const whzIndex = this.headerKeys.indexOf('whz');

      if (sex && (age != null || weight != null || height != null)) {
        const zScores = anthroZscores({
          sex,
          age: convertStringToFloat(age),
          is_age_in_month: true,
          weight: convertStringToFloat(weight),
          lenhei: convertStringToFloat(height),
          measure,
          headc: undefined,
          armc: undefined,
          triskin: undefined,
          subskin: undefined,
          oedema: edema,
          clothing: clothes,
          clothing_weight: this.clothingWeight
        });
        if (zScores) {
          zScoresToUpdate.push([rowIndex, wazIndex, zScores.zwei]);
          zScoresToUpdate.push([rowIndex, hazIndex, zScores.zlen]);
          zScoresToUpdate.push([rowIndex, whzIndex, zScores.zwfl]);
        }
      }

      return zScoresToUpdate;
    },
    updateTableAfterSave(response) {
      const creations = response
        .filter(
          (item) =>
            (item[0] === 'created' || item[0] === 'updated') &&
            item[1] &&
            item[1].changes &&
            item[1].normalized_submission_id != null,
        )
        .map((item) => item[1]);
      const idUpdates = creations.map((creation) => [
        [
          creation.changes[0][0],
          this.headerKeys.indexOf('normalized_submission_id'),
          creation.normalized_submission_id
        ],
        [creation.changes[0][0], this.headerKeys.indexOf('source'), creation.source]
      ]);

      const rowsToRemove = response
        .filter(
          (item) =>
            item[0] === 'deleted' &&
            item[1] &&
            'normalized_submission_id' in item[1] &&
            !item[1].changes?.some((chng) => chng.includes('clear_row')),
        )
        .map((item) => [
          // map row numbers to be deleted
          item[1]?.changes[0] ? item[1].changes[0][0] : -1,
          1
        ])
        .filter((item) => item[0] !== -1);

      const changesToReverse = response
        .filter((item) => item[0] === 'revert' && item[1] && item[1].changes)
        .map((item) => item[1].changes)
        .map((changes) => changes.map(([row, column, old]) => [row, column, old]));
      try {
        this.$refs.hot.hotInstance.batch(() => {
          this.$refs.hot.hotInstance.setDataAtCell(flatten(idUpdates), 'update_id');
          this.$refs.hot.hotInstance.setDataAtCell(flatten(changesToReverse, true), 'reversing_change');

          if (rowsToRemove.length > 0) {
            this.$refs.hot.hotInstance.alter('remove_row', rowsToRemove, 'remove_row_by_api_response');
            this.$refs.hot.hotInstance.deselectCell();
            this.reRenderingCells(true);
          }
        });
        this.$message.success({ content: this.$t('components.notifications.dataUpdated'), key: uploadMessageKey });
        this.updateCellErrorBarData();
      } catch (err) {
        this.$message.error({ content: this.$t('components.notifications.errorSavingRow'), duration: 5 });
      }
    },
    updateTableAfterFileSave(response) {
      const itemsToAdd = response.map(item => omit(item[1], 'changes'));
      const rowToEdit =
        this.$refs.hot.hotInstance.countSourceRows() -
        this.$refs.hot.hotInstance.countEmptyRows(true);
      const changes = itemsToAdd.map((item, rowIndex) => {
        const rowChanges = [];
        this.headerConfigs.forEach((headerKey, index) => {
          if (headerKey?.config?.dateFormat) {
            const dates = formatSmartDates(item[headerKey.key], headerKey.config.dateFormat);
            rowChanges.push([rowToEdit + rowIndex, index, dates]);
          } else {
            rowChanges.push([rowToEdit + rowIndex, index, item[headerKey.key]]);
          }
        });
        return rowChanges;
      });
      this.$refs.hot.hotInstance.setDataAtCell(flatten(changes), 'updateTableAfterFileSave');
      this.onEntryChange();
    },
    async saveRow(changes) {
      if (this.backspaceDown && !(this.disableClusters && changes.length === 1 && changes[0][1] === this.headerKeys.indexOf('cluster'))) {
        this.$message.loading({ content: this.$t('components.notifications.deletingData'), key: uploadMessageKey });
        this.backspaceDown = false;
      }
      const filtered = filterEmptyChanges(changes);
      const monthsIndex = this.headerConfigs.findIndex((config) => config.key === 'months');
      const shapedChanges = shapeAnthroUpdateRequestData(
        filtered,
        this.$refs.hot.hotInstance,
        this.headerConfigs,
      ).filter((item) => {
        if (!item.normalized_submission_id && item.changes.every((chng) => chng[4] === 'UndoRedo.undo' && chng[1] === monthsIndex)) {
          return false;
        }
        return true;
      });
      if (shapedChanges.length === 0) {
        return null;
      }
      try {
        const projectId = this.$store.state.survey.project.id;
        const { surveyId } = this.$store.state.survey;
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;
        const axiosConfig = {
          method: 'POST',
          url: `/projects/${projectId}/child-anthro/${surveyId}`,
          data: shapedChanges
        };
        const response = await Vue.prototype.$http.request(
          configForPossibleBackendRequest(axiosConfig, token),
        );
        this.reRenderingCells(true);
        this.updateTableAfterSave(response.data);
        this.countRows();
        this.onEntryChange();
        return response.data;
      } catch (error) {
        const changesToReverse = [];
        for (const [row, column, old] of changes) {
          changesToReverse.push([row, column, old]);
        }
        this.$refs.hot.hotInstance.setDataAtCell(changesToReverse, 'reversing_change');
        this.$message.error({ content: this.$t('components.notifications.errorSavingRow'), duration: 5 });
        return Promise.reject(error);
      } finally {
        if (this.waitingForDataSetDeleteRequest === true) {
          this.closeDeleteDataSetModal();
          this.waitingForDataSetDeleteRequest = false;
        }
      }
    },
    countRows() {
      this.childrenCount = countFilledRows(cloneDeep(this.data));
    },
    translateHeaders() {
      const translatedHeaders = [
        {
          key: 'normalized_submission_id',
          title: this.$t('components.titles.normalized_submission_id')
        }, {
          key: 'survdate',
          title: this.$t('components.titles.survdate')
        }, {
          key: 'cluster',
          title: this.$tc('components.titles.cluster', 1)
        }, {
          key: 'team',
          title: this.$tc('components.titles.team', 1)
        }, {
          key: 'child_id',
          title: this.$t('values.id')
        }, {
          key: 'hh',
          title: this.$t('components.titles.hh')
        }, {
          key: 'sex',
          title: this.$t('components.titles.sex')
        }, {
          key: 'birthdate',
          title: this.$t('components.titles.birthdate')
        }, {
          key: 'months',
          title: this.$tc('components.titles.month', 2)
        }, {
          key: 'weight',
          title: this.$t('values.weight')
        }, {
          key: 'height',
          title: this.$t('values.height')
        }, {
          key: 'edema',
          title: this.$t('components.titles.edema')
        }, {
          key: 'muac',
          title: this.$t('components.titles.muac')
        }, {
          key: 'waz',
          title: this.$t('values.waz')
        }, {
          key: 'haz',
          title: this.$t('values.haz')
        }, {
          key: 'whz',
          title: this.$t('values.whz')
        }, {
          key: 'measure',
          title: this.$t('components.titles.measure')
        }, {
          key: 'clothes',
          title: this.$t('components.titles.clothes')
        }, {
          key: 'strata',
          title: this.$t('components.titles.strata')
        }, {
          key: 'wtfactor',
          title: this.$t('components.titles.wtfactor')
        }, {
          key: 'source',
          title: this.$t('components.titles.source')
        }
      ];
      return translatedHeaders;
    },
    deleteDataSet(dataset) {
      this.deleteDataSetModalVisible = true;
      this.dataSetToDelete = dataset;
    },
    closeDeleteDataSetModal() {
      this.deleteDataSetModalVisible = false;
      this.dataSetToDelete = '';
    },
    setDataFromDeleteDatasetModal(changes) {
      this.waitingForDataSetDeleteRequest = true;
      this.$refs.hot.hotInstance.setDataAtCell(changes);
    }
  }
};
</script>

<style lang="scss">
.result-data-entry {
  .rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .blue-color {
    color: #3897ff;
  }
  .htCommentTextArea.blue {
    border: 1px solid #3897ff;
    border-left: 3px solid #3897ff;
  }
  .expand-error {
    background-color: rgba(244, 40, 40, 0.1);
    margin-bottom: 20px;
    padding-right: 20px;
  }

  .red-color {
    color: #f42828;
  }
  .htCommentTextArea.red {
    border: 1px solid #f42828;
    border-left: 3px solid #f42828;
  }

  .orange-color {
    color: #f49402;
  }
  .htCommentTextArea.orange {
    border: 1px solid #f49402;
    border-left: 3px solid #f49402;
  }

  .handsontable thead tr td:first-child {
    display: none;
  }

  .handsontable .htCommentCell.red {
    background: rgba(244, 40, 40, 0.08);
    color: #f42828;
  }
  .handsontable .htCommentCell.red:after {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #f42828;
  }


  .handsontable .htDimmed {
    background: #f4f4f5;
  }

  .handsontable .htCommentCell.orange {
    background: rgba(250, 255, 0, 0.25);
    color: #f49402;
  }
  .handsontable .htCommentCell.orange:after {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #f49402;
  }

  .handsontable .htCommentCell.blue{
    background: rgba(56, 151, 255, 0.1);
    color: #3897ff;
  }

  .handsontable .htCommentCell.blue:after {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #3897ff;
  }

  .table-footer {
    border-top: 1px solid #ccc;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 23px;
  }

  .handsontable span.colHeader.columnSorting::before {
    content: none;
  }
}
</style>
